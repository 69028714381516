/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { isMobile } from 'react-device-detect';
import { trackPageView } from "../services/session";

function SEO({ description, lang, meta, page, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  function toggleChatWidget() {

    if (window.Tawk_API && window.Tawk_API.showWidget) {
      if (page && page == "build") {
        window.Tawk_API.hideWidget();
      }
      else {
        window.Tawk_API.showWidget();
      }

      return true;
    }
  }

  useEffect(
    () => {
        if (isMobile) {
          if (!toggleChatWidget()) {
            // try again
            window.setTimeout(toggleChatWidget, 5050);
          }          
        }
    }, 
    []
  );

  useEffect(
    () => {
      if (page) {
        setTimeout(() => {
          trackPageView(page);
        }, 500)
      }
    }, 
    [page]
  );

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s `}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  name: '',
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
}

export default SEO
